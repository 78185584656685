import PropTypes from "prop-types";
import React from "react";

import Header from "./header";

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900">
      <Header />

      <main className="flex-1 w-full pb-8 mx-auto md:pb-16 max-w-screen-xl">
        {children}
      </main>

      <footer className="bg-gray-700">
        <nav className="flex justify-between max-w-4xl p-4 mx-auto text-sm md:p-8">
          <div>
            <p className="text-white font-bold text-2xl my-5">
              株式会社CatDriven
            </p>
            <p className="text-gray-50">
              〒810-0001 <br />
              福岡県福岡市中央区天神二丁目3番10号天神パインクレスト719号
            </p>
          </div>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
