import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import logo from "../images/logo.png";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

function Header() {
  const HeaderLink = styled(Link)`
    &:hover {
      color: #ffb54f;
    }
  `;
  const HeaderDiv = styled.div`
    ${tw`flex items-center max-w-4xl p-4 mx-auto md:p-8`}
    @media screen and (max-width: 768px) {
      align-items: flex-start;
    }
  `;
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header className="bg-white border-b-2">
      <HeaderDiv>
        <Link to="/">
          <h1 className="text-black no-underline">
            <img
              alt={site.siteMetadata.title}
              className="block mx-10 w-1/2"
              src={logo}
            />
          </h1>
        </Link>

        <div>
          <button
            className="block px-3 py-2 text-black border border-black rounded md:hidden"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className="w-3 h-3 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
          <nav
            className={`${
              isExpanded ? `block` : `hidden`
            } md:block md:items-center w-full md:w-auto`}
          >
            {[
              {
                route: `/about`,
                title: `About`,
              },
              {
                route: `/contact`,
                title: `Contact`,
              },
            ].map((link) => (
              <HeaderLink
                className="{css``} block mt-4 text-black no-underline md:inline-block md:mt-0 md:ml-6"
                key={link.title}
                to={link.route}
              >
                {link.title}
              </HeaderLink>
            ))}
          </nav>
        </div>
      </HeaderDiv>
    </header>
  );
}

export default Header;
